import React from 'react'
import { Row, Col } from 'reactstrap'

const Answers = () => (
  <section className="answers-section">
    <div className="content-restriction">
      <h3 className="ml-n1">Immediate answers to business questions</h3>
      <span className="sub-header">A conversational interaction with your company's data</span>

      <Row className="pt-5">
        <Col xs={12}>
          <dl className="dl-list-wrapper">
            <dt>Past workflow</dt>
            <dd>
              <div className="schema-wrapper">
                <span className="block">Business users</span>
                <ul>
                  <li>Question</li>
                  <li className="arrow right" />
                  <li className="arrow left" />
                  <li>Answer</li>
                </ul>
                <span className="block">Data teams</span>
                <ul>
                  <li>Delegation</li>
                  <li className="arrow right" />
                  <li className="arrow left" />
                  <li>Answer</li>
                </ul>
                <span className="block">Analysts</span>
                <ul>
                  <li className="arrow right" />
                  <li>Processing</li>
                  <li className="arrow left" />
                </ul>
                <span className="block">Sources</span>
              </div>
            </dd>
          </dl>
        </Col>

        <Col xs={12}>
          <dl className="dl-list-wrapper mt-5">
            <dt>Future workflow</dt>
            <dd>
              <div className="schema-wrapper">
                <span className="block">Business users</span>
                <ul>
                  <li>Question</li>
                  <li className="arrow right" />
                  <li className="arrow left" />
                  <li>Answer</li>
                </ul>
                <span className="block special">Generative AI</span>
                <ul>
                  <li className="arrow right" />
                  <li>Processing</li>
                  <li className="arrow left" />
                </ul>
                <span className="block">Sources</span>
              </div>
            </dd>
          </dl>
        </Col>
      </Row>
    </div>
  </section>
)

export default Answers
