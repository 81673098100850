import React, { useState } from 'react'
import ReCAPTCHA from "react-google-recaptcha"
import { useFormik } from 'formik'
import * as Yup from 'yup'
import classnames from 'classnames'
import {
  Button,
  Modal,
  CardBody,
  Form,
  Col,
  Row,
  Input,
  FormGroup,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  UncontrolledAlert,
} from 'reactstrap'

import { requestCall } from 'helpers/request'

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  first_name: Yup.string().required(),
  last_name: Yup.string().required(),
  message: Yup.string().required(),
  'g-recaptcha-response': Yup.string().required(),
})

const SITE_KEY = '6Lf7oKEaAAAAAM7jCkLslwxi2upiaWG4RiNY7uqM'

const ContactUsModal = ({ setShowContactUs, showContactUs }) => {
  const [showSuccess, setShowSuccess] = useState(false)
  const [focused, setFocus] = useState({
    firstName: false,
    lastName: false,
    email: false,
    message: false,
  })

  const submitForm = async (data, { resetForm }) => {
    try {
      await requestCall({
        url: 'v2/contact',
        method: 'POST',
        multipart: true,
        data,
      })
      resetForm()
      setShowSuccess(true)
      setTimeout(() => {
        setShowContactUs(false)
        setShowSuccess(false)
      }, 5000)
    } catch (err) {
      alert('Opps! Something wrong! :(')
      console.error(err)
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
      message: '',
      'g-recaptcha-response': '',
    },
    validationSchema,
    onSubmit: submitForm,
  })

  return (
    <Modal
      isOpen={showContactUs}
      toggle={() => setShowContactUs(false)}
      className="modal-dialog-centered modal-secondary"
    >
      <div className="modal-body">
        {showSuccess && (
          <UncontrolledAlert color="success">
            <span className="alert-text ml-1">
              <strong>Thank you!</strong> Your request has been sent. We will reach you shortly.
            </span>
          </UncontrolledAlert>
        )}
        <Form>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <InputGroup
                    className={classnames("input-group-merge", {
                      focused: focused.firstName,
                      'has-danger': formik.touched.first_name && Boolean(formik.errors.first_name),
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-user" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="First Name"
                      type="text"
                      name="first_name"
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                      onFocus={() => setFocus(state => ({...state, firstName: true }))}
                      onBlur={() => setFocus(state => ({...state, firstName: false }))}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <InputGroup
                    className={classnames("input-group-merge", {
                      focused: focused.lastName,
                      'has-danger': formik.touched.last_name && Boolean(formik.errors.last_name),
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-user" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Last Name"
                      type="text"
                      name="last_name"
                      value={formik.values.last_name}
                      onChange={formik.handleChange}
                      onFocus={() => setFocus(state => ({...state, lastName: true }))}
                      onBlur={() => setFocus(state => ({...state, lastName: false }))}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <InputGroup
                className={classnames("input-group-merge", {
                  focused: focused.email,
                  'has-danger': formik.touched.email && Boolean(formik.errors.email),
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-envelope" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Email Address"
                  type="text"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onFocus={() => setFocus(state => ({...state, email: true }))}
                  onBlur={() => setFocus(state => ({...state, email: false }))}
                />
              </InputGroup>
            </FormGroup>
            <div className={classnames('form-group label-floating', { 'has-danger': formik.touched.message && Boolean(formik.errors.message) })}>
              <textarea
                name="message"
                className="form-control"
                id="message"
                rows="6"
                placeholder="Your Message"
                value={formik.values.message}
                onChange={formik.handleChange}
              />
            </div>
            <Row className="justify-content-center">
              <ReCAPTCHA
                sitekey={SITE_KEY}
                onChange={(response) => { formik.setFieldValue("g-recaptcha-response", response) }}
              />
            </Row>
          </CardBody>
        </Form>
      </div>
      <div className="modal-footer">
        <Button
          className="new-event--add"
          color="primary"
          type="button"
          onClick={formik.handleSubmit}
          disabled={!(formik.isValid && formik.dirty)}
          style={{ background: '#0057FF' }}
        >
          Send Message
        </Button>
        <Button
          className="ml-auto"
          color="link"
          type="button"
          onClick={() => setShowContactUs(false)}
        >
          Close
        </Button>
      </div>
    </Modal>
  )
}

export default ContactUsModal
