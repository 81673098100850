import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import cp_history from 'assets/img/platform-page/history.png'
import cp_missing from 'assets/img/platform-page/missing.png'
import cp_dataset from 'assets/img/platform-page/dataset.png'
import cp_heatmap from 'assets/img/platform-page/heatmap.jpg'
import cp_modelling from 'assets/img/platform-page/modelling.png'
import cp_prediction from 'assets/img/platform-page/prediction.jpg'
import cp_integrations from 'assets/img/platform-page/integrations.jpg'

const { REACT_APP_ENVIRONMENT } = process.env

const Platform = () => (
  <section>
    <div style={{ height: '90px' }} />
    <Container>
      <Row className="justify-content-center">
        <Col md={6}>
          <h1 className="title">Divian Platform</h1>
          <h4>
            From data ingestion, storage and handling<br/>
            to insights generation and predictive modelling, <br/>
            Divian is the <b>fast and intuitive environment for data science</b>.
          </h4><br/>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md={6} className="text-center">
          <h6 class="text-muted">Divian makes</h6>
          <h2 class="title">Working with Your Data Easy</h2>
          <h5 class="description">Tasks like data upload, storage, cleanup, merging and versioning are simple, intuitive and really quick on Divian Platform.</h5><br/><br/>
        </Col>
      </Row>

      {/* SECTION 1 */}
      <Row className="justify-content-center">
        <Col md={4}>
          <div class="card-plain card">
            <div class="card-img-top">
              <a href="#signup"><img alt="" class="img" src={cp_dataset} style={{ maxWidth: '100%' }} /></a>
            </div>
            <div class="card-body">
              <a href="#signup"><h3 class="card-title">Cloud Storage</h3><br/></a>
              <h6 class="card-category text-muted">Secure data object store</h6>
              <p class="card-description">
                Your entire organization can now build datasets in the cloud,
                and effortlessly merge data from multiple sources, including user files, SQL, Dropbox*, Snowflake*, and more*
              </p>
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div class="card-plain card">
            <div class="card-img-top">
            <a href="#signup"><img alt="" class="img" src={cp_history} style={{ maxWidth: '100%' }} /></a>
            </div>
            <div class="card-body">
              <a href="#signup"><h3 class="card-title">Full History</h3><br/></a>
              <h6 class="card-category text-muted">Data versioning and cloud backups</h6>
              <p class="card-description">
                Access full build history for your data objects.
                Restore data objects to previous versions.
              </p>
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div class="card-plain card">
            <div class="card-img-top">
              <a href="#signup"><img alt="" class="img" src={cp_missing} style={{ maxWidth: '100%' }} /></a>
            </div>
            <div class="card-body">
              <a href="#signup"><h3 class="card-title">Clean Data</h3></a><br/>
              <h6 class="card-category text-muted">Automatic data cleaning and validation</h6>
              <p class="card-description">
                Get detailed dataset profiles.
                Parse special formatting such as currencies, percentages etc.
                Automatically identify data issues and
                correct them using build-in tools.
              </p>
            </div>
          </div>
        </Col>
        <Col md={12} className="text-center">
          <a href={`https://cloud.divian.${REACT_APP_ENVIRONMENT === 'production' ? 'com' : 'dev'}/registration`} className="btn btn-success btn text-center">Join Divian Now</a>
        </Col>
      </Row>


      {/* SECTION 2 */}
      <Row className="justify-content-center">
        <Col md={8} className="text-center">
          <h6 class="text-muted">Divian makes</h6>
          <h2 class="title">Advanced Data Analytics Accessible</h2>
          <h5 class="description">Divian Platform lets anyone on your team become your data scientist, data architect and data technician.
          </h5><br/><br/>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <div class="card-plain card">
            <div class="card-img-top">
              <a href="#signup">
                <img alt="" class="img" src={cp_heatmap} style={{ maxWidth: '100%' }} /></a>
            </div>
            <div class="card-body">
              <a href="#signup"><h3 class="card-title">Data Exploration</h3></a><br/>
              <h6 class="card-category text-muted">Relationships in your data, uncovered </h6>
              <p class="card-description">
              See and understand complex relationships in the data with visual mapping instruments.
              Uncover hard-to-see, multi-dimensional insights with our proprietary advanced tools.
              </p>
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div class="card-plain card">
            <div class="card-img-top">
            <a href="#signup"><img alt="" class="img" src={cp_modelling} style={{ maxWidth: '100%' }} /></a>
            </div>
            <div class="card-body">
              <a href="#signup"><h3 class="card-title">Modelling</h3><br/></a>
              <h6 class="card-category text-muted">Data models without writing code</h6>
              <p class="card-description">
              Access full build history for your data objects.
              Restore data objects to previous versions.
              </p>
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div class="card-plain card">
            <div class="card-img-top">
              <a href="#signup"><img alt="" class="img" src={cp_prediction} style={{ maxWidth: '100%' }} /></a>
            </div>
            <div class="card-body">
              <a href="#signup"><h3 class="card-title">Predictive Analytics</h3></a><br/>
              <h6 class="card-category text-muted">Make predictions with a few clicks</h6>
              <p class="card-description">
                Set custom values for model features, instantly update model output,
                estimate upper and lower ranges for your forecasts.
              </p>
            </div>
          </div>
        </Col>
        <Col md={12} className="text-center">
          <a href={`https://cloud.divian.${REACT_APP_ENVIRONMENT === 'production' ? 'com' : 'dev'}/registration`} className="btn btn btn text-center">Sign up now</a>
        </Col>
      </Row>

      {/* SECTION 3 */}
      <Row className="justify-content-center">
        <Col md={8} className="text-center">
          <h6 class="text-muted">Divian makes</h6>
          <h2 class="title">Code Integrations Simple</h2>
          <h5 class="description">Full Python access to all Divian technology and objects.
          </h5><br/><br/>
        </Col>
        <Col md={8}>
          <div class="card-plain card">
            <div class="card-img-top">
            <a href="#signup"><img alt="" class="img" src={cp_integrations} style={{ maxWidth: '100%' }} /></a>
            </div>
            <div class="card-body">
              <a href="#signup"><h3 class="card-title">Cloud + Code</h3><br/></a>
              <h6 class="card-category text-muted">Write code much faster</h6>
              <p class="card-description">
                Switch between codeless and code-based workflows.
                Work with any Divian object using Python API.
                Use Divian API to add a predictive layer to your apps.
              </p>
            </div>
          </div>
        </Col>
        <Col md={12} className="text-center">
          <a href={`https://cloud.divian.${REACT_APP_ENVIRONMENT === 'production' ? 'com' : 'dev'}/registration`} className="btn btn-success btn text-center">Start using Divian</a>
        </Col>
      </Row>
    </Container>
  </section>
)

export default Platform
