import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import cp_api from 'assets/img/api-page/api1.png'

const { REACT_APP_ENVIRONMENT } = process.env

const API = () => (
  <section>
    <div style={{ height: '40px' }} />
    <Container>
      <Row className="justify-content-center">
        <Col md={8} className="text-center">
          <h1 className="title" style={{ marginLeft: '-4px', marginTop: '10%' }}>Divian API</h1>
          <h4>Empower your products with our Python toolkit</h4>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md={8} className="text-center">
          <h2 class="title">Integrate Advanced Data Science in Your Applications</h2>
          <h5 class="description">Full Python access to all Divian technology and objects.
          </h5><br/>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md={12}>
          <div class="card-plain card">
            <div class="card-img-top">
            <a href="#signup"><img alt="" class="img" src={cp_api} style={{ maxWidth: '100%' }} /></a>
            </div>
          </div>
        </Col>
        <Col md={10} className="text-center">
          <div class="card-plain card">
            <div class="card-body">
              <p class="card-description">
                Supercharge your applications with advanced data intelligence.
                Get a turnkey curated solution for storing data objects in the cloud.
                Build predictive models and forecast anything on the fly, blazingly fast and at scale.

                Switch between codeless and code-based workflows.
                Work with any Divian object using Python API.
                Use Divian API to add a predictive layer to your apps.
              </p>
            </div>
          </div>
          <a href={`https://cloud.divian.${REACT_APP_ENVIRONMENT === 'production' ? 'com' : 'dev'}/registration`} className="btn btn-success btn text-center">Start now</a>
        </Col>
      </Row>
    </Container>
  </section>
)

export default API
