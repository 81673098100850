import React from 'react'
import { Container, Row, Col } from 'reactstrap'

const Terms = () => (
  <section>
    <div style={{ height: '90px' }} />
    <Container>
      <Row className="justify-content-center">
        <Col md={8}>
          <h1 className="title">Terms of Use</h1>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md={8}>
          <h5><strong>Divian, Inc.</strong><br/></h5>
          <h6 className="h3">Last Updated: September 1, 2021</h6><br/>

          <h4><strong>PLEASE READ THESE TERMS BEFORE USING DIVIAN</strong>.</h4><br/>
          <h5>
            These Terms of Use govern your access to and use of Divian, Divian Cloud (cloud.divian.com),
            Divian Labs (labs.divian.com) Divian API (api.divian.com) and Divian Learn (learn.divian.com)
            (collectively the “Divian Services”). These Terms of Use limit Divian’s liability and obligations to you,
            impose certain obligations on you, allow Divian to suspend or terminate your access to and use of
            the Divian Services, and provide you with other important information with respect to the provision
            and use of the Divian Services. The Divian Services enable users to upload and share data and create
            analyses that have been built using Divian’s proprietary software programs.
          </h5>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md={8}>
        <div style={{paddingTop: '3%'}}>
            You agree that by signing up to use the Divian Services and registering as a user or by using the
            Divian Services (in each case, you are a “User”), you are entering into a legally binding agreement
            with Divian, Inc. (“we,” “us,” “our,” and “Divian”). IF YOU RESPOND BY CLICKING ON THE “LOG IN”,
            “SIGN UP”, “I AGREE”, “I ACCEPT” OR SIMILAR BUTTON TO WHICH THIS AGREEMENT IS LINKED, REFERENCED
            OR OTHERWISE ATTACHED, YOU ACKNOWLEDGE AND AGREE THAT (i) IF YOU ARE USING THE DIVIAN SERVICES ON
            BEHALF OF A COMPANY OR OTHER LEGAL ENTITY (“COMPANY”), YOU REPRESENT THAT YOU HAVE THE AUTHORITY
            TO BIND SUCH ENTITY TO THESE TERMS AND CONDITIONS AND (II) YOU HAVE READ AND UNDERSTAND THESE TERMS
            OF USE AND ARE AGREEING THAT YOU AND COMPANY WILL BE BOUND BY THESE TERMS OF USE. IF YOU ARE NOT
            AUTHORIZED TO BIND THE COMPANY TO THIS AGREEMENT OR DO NOT AGREE TO THE TERMS OF THIS AGREEMENT,
            DO NOT CLICK ON THE “LOG IN”, “SIGN UP”, “I AGREE”, “I ACCEPT” OR SIMILAR BUTTON, DO NOT CONCLUDE
            THE SIGN UP OR REGISTRATION PROCESS, AND DO NOT USE THE Divian Services.
          </div>
          <div style={{paddingTop: '3%'}}>
            Divian may update these Terms of Use from time to time, to, among other things, reflect changes to the
            Divian Services. We will notify you of changes to these Terms of Use by posting an updated
            version at <a href="https://divian.com/terms">https://divian.com/terms</a> and will revise the “Last Updated”
            date above. We encourage you to periodically review these Terms of Use to be informed with respect to your
            and Divian’s rights and obligations with respect to the Divian Services.
            Using the Divian Services after a notice of changes has been sent to you
            or published on the Divian Services shall constitute consent to the changed terms and practices.
            <br/>
            By agreeing to these Terms of Use, you are also agreeing to our website terms of use
            located at <a href="https://divian.com/terms">https://divian.com/terms</a> and
            our Privacy Policy located at <a href="https://divian.com/privacy">https://divian.com/privacy</a>.
            Please review these additional terms and conditions carefully.
          </div>
          <div style={{paddingTop: '3%'}}>
            <h6 className="h3">Right to Use the Divian Services</h6>
            On the condition that you comply with all your obligations under this Agreement, we grant you a limited,
            revocable, nonexclusive, nonassignable, nonsublicenseable right to access, through a generally available
            web browser or mobile device or application (but not through scraping, spidering, crawling or other
            technology or software used to access data without the express written consent of Divian) and use the
            Divian Services in accordance with these Terms of Use, the Acceptable Use Policy and any usage limitations
            applicable to the Divian Services plan to which You have subscribed. Any other use of the Divian Services
            is strictly prohibited and a violation of the Terms of Use. We reserve all rights not expressly granted
            in this Agreement.
            You understand that we use third-party vendors and hosting partners to provide the necessary hardware,
            software, networking, storage, and related technology required to run the Service. You understand that
            he technical processing and transmission of the Divian Services, including your datasets, your analyses
            and any data or information you provide or store in the Divian Services or any application made available
            via the Divian Services, may be transferred unencrypted and involve (a) transmissions over various networks;
            and (b) changes to conform and adapt to technical requirements of connecting networks or devices.
          </div>
          <div style={{paddingTop: '3%'}}>
            <h6 className="h3">Availability of the Divian Services and Eligibility to Use the Divian Services</h6>
            You have the right to access and use the Divian Services as it exists on any given day and Divian
            has no other obligation with respect to updating, improving, maintaining, or making available the
            Divian Services. Further, Divian reserves the right, any time and from time to time, to update, modify,
            revise, replace, refuse access to, suspend or discontinue the Divian Services, temporarily or p
            ermanently, partially or entirely, with or without notice. All of these changes shall be effective
            upon their posting on our website or by direct communication to you unless otherwise noted. Divian
            shall not be liable to you or to any third-party for any update, modification, revision, replacement,
            price change, suspension,
            refusal of access or discontinuance of the Divian Services or any of your datasets, analyses, data
            or information.
            To be eligible to use the Divian Services, you must meet the following criteria and represent and
            warrant that you: (1) are 13 years of age or older; (2) are a human - accounts registered by “bots”
            or other automated methods are not permitted unless authorized or enabled by Divian; (3) are not currently
            restricted from the Divian Services; (4) are not a competitor of Divian or are not using the Services
            for reasons that are in competition with Divian; (5) have full power and authority to enter into this
            Agreement and doing so will not violate any other agreement to which you are a party; (6) will not
            violate any rights of Divian, including intellectual property rights such as copyright or trademark
            rights; (7) will comply with the Terms of Use and Acceptable Use Policy, as each may be amended from
            time to time, as well as comply with Divian’s website terms of use referenced above; and (8) agree to
            provide at your cost all equipment, software, and internet access necessary to use the Divian Services.
          </div>
          <div style={{paddingTop: '3%'}}>
            <h6 className="h3">Sign Up and Registration</h6>
            To use the Divian Services, you must complete the sign up process and register as a User. You agree to:
            (a) provide true, accurate, current and complete information about yourself as prompted by the sign up
            process and registration form (“User Data”) and (b) maintain and promptly update the User Data to keep
            it accurate and current. If you provide any information that is inaccurate or not current, or Divian has
            reasonable grounds to suspect that such information is inaccurate or not current, Divian has the right
            to suspend or terminate your account and refuse any and all current or future use of the Divian Services.
            Your login may only be used by one person — a single login shared by multiple people is not permitted.
            You may create separate logins for as many people as your plan allows.
          </div>
          <div style={{paddingTop: '3%'}}>
            <h6 className="h3">Account Security</h6>
            You are responsible for safeguarding the password that you use to access the Divian Services.
            You agree to: (1) keep your account password secure and confidential; and (2) not permit others
            to use your account. Further, you are responsible for any activities or actions under your account
            or anything that happens through your account, whether or not you have authorized such activities or
            actions, until you disable your account or prove that your account security was compromised due to
            no fault of your own. You will immediately notify Divian of any unauthorized use of your account.
            You acknowledge that if you wish to protect your transmission of data and/or files to Divian, it is
            your responsibility to use a secure encrypted connection to communicate with and/or utilize the Divian Services.
            <p/>
            By using the Divian Services, you represent and warrant that (i) you are in compliance with these Terms of Use,
            (ii) you own or otherwise have all rights and permissions necessary to submit to Divian and the Divian Services
            any data, analyses or other information that you submit to the Divian Services, and (iii) any data, analyses
            or other information that you submit to the Divian Services does not violate, misappropriate or infringe the
            intellectual property rights of any third party and is not in violation of any contractual restrictions or
            other third party rights. If you have any doubts about whether you have the legal right to submit, share or
            license data and any analyses, or other information, you should not submit data or any analyses, or other
            information that you submit to the Divian Services to the Divian Services. You may remove data from the
            Divian Services at any time or if the Divian Services does not include a feature that permits you to remove
            data, you may request that Divian remove data at any time by contacting Divian support at support@divian.com.
            <p/>
            Divian does not claim ownership of data; however, you hereby grant Divian a worldwide, perpetual, irrevocable,
            royalty-free, fully paid up, transferable and non-exclusive license, as applicable, to (i) access, use, copy,
            adapt, publicly perform and publicly display datasets and any analyses, data, or other information that you
            submit to the Divian Services in connection with providing the Divian Services to you.If you choose to, you
            can share your datasets, and any analyses, data, or other information that you submit to the Divian Services,
            with the general public, or with specific individuals or Users you select to the extent the Divian Services
            supports such functionality. If you decide to share your datasets, and/or any analyses, data, or other
            information that you submit to the Divian Services, you are giving certain legal rights, as explained below,
            to those individuals who you have given access. Divian has no responsibility to enforce or police, or aid
            you in enforcing or policing, the terms of the license(s) or permission(s) you have chosen to offer. Divian
            IS NOT RESPONSIBLE FOR MISUSE OR MISAPPROPRIATION OF YOUR DATASETS AND /OR ANY ANALYSES, DATA, OR OTHER
            INFORMATION THAT YOU SUBMIT TO THE DIVIAN SERVICES BY THIRD PARTIES.
            <p/>
            Divian reserves the right to remove and/or disable any of your datasets and any analyses, data, or other
            information that you submit to the Divian Services, with or without notice, if deemed by Divian to be
            contrary to the Terms of Use or Acceptable Use Policy. For avoidance of doubt, Divian has no obligation
            o store, maintain or provide you a copy of any of your datasets and any analyses, data, or other information
            submitted to the Divian Services and any of your datasets and any analyses, data, or other information
            that you submit are at your own risk of loss.
          </div>
          <div style={{paddingTop: '3%'}}>
            <h6 className="h3">Notifications and Service Messages</h6>
            For purposes of service messages and notices about the Divian Services to you, you agree that Divian may
            notify you via the email address associated with your account, even if we have other contact information.
            You also agree that Divian may communicate with you through your Divian account or through other means
            including email, mobile number or telephone. You acknowledge and agree that we shall have no liability
            associated with or arising from your failure to maintain accurate contact or other information, including,
            but not limited to, your failure to receive critical information about the Divian Services.
          </div>
          <div style={{paddingTop: '3%'}}>
            <h6 className="h3">Restrictions on Use</h6>
              You must comply with all applicable laws, rules, regulations and guidelines, and you further agree
              not to do any of the following:<p/>
              <ul>
                <li>
                  Post, publish or transmit any data, information, files, text, graphics, or other material that: (i)
                  is false or misleading; (ii) is defamatory; (iii) invades another’s privacy; (iv) is obscene,
                  pornographic, or offensive; (v) promotes bigotry, racism, hatred or harm against any individual
                  or group; (vi) infringes, violates or misappropriates another’s rights, including any intellectual
                  property rights; or (vii) violates, or encourages any conduct that would violate, any applicable
                  law, rule or regulation (including those with respect to privacy and/or data security) or would
                  give rise to civil liability;
                </li>
                <li>
                  Access, tamper with, or use non-public areas of the Divian Services or Divian’s or its third party
                  hosting providers’ computer systems;
                </li>
                <li>
                  Attempt to probe, scan, or test the vulnerability of any system or network or breach any security
                  or authentication measures;
                </li>
                <li>
                  Attempt to access or search the Divian Services with any engine, software, tool, agent, device or
                  mechanism other than the software and/or search agents provided by Divian or other generally
                  available third-party web browsers (such as Microsoft Internet Explorer or Mozilla Firefox),
                  including but not limited to browser automation tools;
                </li>
                <li>
                  Attempt to decipher, decompile, disassemble or reverse engineer any of the software used
                  to provide the Divian Services;
                </li>
                <li>
                  Interfere with, or attempt to interfere with the Divian Services or the access of any user,
                  host or network, including, without limitation, sending a virus or other malicious code,
                  overloading, flooding, or spamming the Divian Services; or plant malware on Divian’s computer
                  systems, those systems of Divian’s providers, or otherwise use the Divian Services to attempt
                  to distribute malware or other malicious code;
                </li>
                <li>
                  Use the Divian Services in any illegal or unlawful manner or for any illegal or unlawful purpose;
                </li>
                <li>
                  Perform any act which is intended to harm Divian or the Divian Services; or Impersonate or
                  misrepresent your affiliation with any person or entity.
                </li>
              </ul>
              Divian has the right to investigate and prosecute violations of any of the above, including intellectual
              property rights infringement and security concerns, to the fullest extent of the law and may involve
              and cooperate with law enforcement authorities in prosecuting those who violate these Terms of Use.
          </div>
          <div style={{paddingTop: '3%'}}>
            <h6 className="h3">Divian Intellectual Property Rights</h6>
            All right, title, and interest in and to the Divian Services is and will remain the exclusive property
            of Divian and its licensors, including all Intellectual Property Rights (as defined below) therein,
            even if Divian incorporates any of your Feedback (as defined below) into subsequent versions.
            The Divian Services is protected by copyright, trademark, and other laws of both Canada and foreign countries.
            All feedback, comments, and suggestions for improvements that you provide to Divian, in any form
            (collectively, the “Feedback”) will be the sole and exclusive property of Divian. You hereby irrevocably
            transfer and assign to Divian all of your right, title, and interest in and to all of your Feedback,
            including all worldwide patent rights (including patent applications and disclosures), copyright rights,
            trade secret rights, and other intellectual property rights (collectively, the “Intellectual Property Rights”)
            therein. At Divian’s request and expense, you will execute documents and take such further acts as Divian
            may reasonably request to assist Divian in acquiring, perfecting and maintaining its Intellectual Property
            Rights and other legal protections for your Feedback. You will not earn or acquire any rights or licenses
            in the Divian Services or in any Divian Intellectual Property Rights on account of these Terms of Use or
            your use of the Divian Services.
            <p/>
            The foregoing intellectual property rights provisions shall survive any termination or expiration of these Terms of Use.
          </div>
          <div style={{paddingTop: '3%'}}>
            <h6 className="h3">Digital Millennium Copyright Act Compliance</h6>
            You will only upload, post, submit or otherwise transmit data and/or files: (i) that you have the lawful
            right to use, copy, distribute, transmit, or display; or (ii) that does not infringe the intellectual
            property rights or violate the privacy rights of any third party (including, without limitation, copyright,
              trademark, patent, trade secret, or other intellectual property right, or moral right or right of publicity).
              Divian has adopted and implemented a policy that permits the deletion of files that violate this policy,
              and will terminate the accounts of Users who repeatedly infringe or are believed to be or are charged
              with repeatedly infringing the rights of copyright holders.
          </div>
          <div style={{paddingTop: '3%'}}>
            <h6 className="h3">Misuse of the Services</h6>
            If you violate any of these Terms of Use, your permission to use the Divian Services will automatically terminate and
            Divian may disable your account. Divian reserves the right to revoke your access to and use of the Divian Services at
            any time, with or without cause, and with or without notice.
            <p/>
            DISCLAIMER OF WARRANTY. The Divian Services, including without limitation all applications, all content and
            information provided therein or thereby, are provided “AS-IS” and without warranty. Your access to and use
            of the Divian Services is at your own risk. Divian disclaims all liability for and is not responsible for
            any harm to your computer system, loss or corruption of data, or other harm that results from your access
            to or use of the Divian Services or any software applications or data made available through the Divian Services.
            <p/>
            WITHOUT LIMITING THE FOREGOING, DIVIAN EXPLICITLY DISCLAIMS ANY AND ALL WARRANTIES OF TITLE, MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT OF THIRD PARTY RIGHTS AND ANY WARRANTIES ARISING OUT OF
            COURSE OF DEALING OR USAGE OF TRADE. YOU ACKNOWLEDGE THAT USE OF THE DIVIAN SERVICES MAY RESULT IN UNEXPECTED
            RESULTS, LOSS OR CORRUPTION OF DATA OR COMMUNICATIONS, PROJECT DELAYS, OTHER UNPREDICTABLE DAMAGE OR LOSS,
            OR EXPOSURE OF YOUR DATASETS OR YOUR INFORMATION OR DATA TO UNINTENDED THIRD PARTIES.<p/>
            DIVIAN MAKES NO WARRANTY THAT THE DIVIAN SERVICES WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED,
            TIMELY, SECURE, OR ERROR-FREE BASIS OR THAT THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE DIVIAN
            SERVICES WILL BE ACCURATE OR RELIABLE OR THAT ANY ERRORS IN THE DIVIAN SERVICES WILL BE CORRECTED.
            DIVIAN MAKES NO WARRANTY REGARDING THE QUALITY OR LEGALITY OF ANY SOFTWARE APPLICATIONS OR INFORMATION OR DATA
            OBTAINED OR USED THROUGH THE DIVIAN SERVICES, OR THAT ANY OF THE FOREGOING WILL MEET YOUR REQUIREMENTS OR BE
            AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS OR THAT THE RESULTS THAT MAY BE OBTAINED FROM
            YOUR USE OF THE FOREGOING WILL BE ACCURATE OR RELIABLE OR THAT ANY ERRORS IN THE FOREGOING WILL BE CORRECTED.
            NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM Divian OR THROUGH THE Divian WEBSITE OR SERVICE,
            WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
            <p/>
            SOME COUNTRIES AND JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF IMPLIED TERMS IN CONTRACTS WITH CONSUMERS AND
            AS A RESULT THE CONTENTS OF THIS SECTION MAY NOT APPLY TO YOU.
            <p/>
            The foregoing disclaimers shall survive any termination or expiration of these Terms of Use.
          </div>
          <div style={{paddingTop: '3%'}}>
            <h6 className="h3">Your Indemnity</h6>
            You agree to defend (at Divian’s request), indemnify, and hold Divian, its officers, directors, employees, agents,
            users, and third party providers harmless from and against any claims, demands, suits, proceedings, investigations,
            damages, losses, costs, expenses and any and all other liabilities, including, without limitation, reasonable
            attorneys’ fees, expenses and costs, arising out of or in any way connected with: (i) your access to or use of
            the Divian Services and any activity in which you engage through the Divian Services; (ii) your failure to comply
            with the Terms of Use; (iii) your violation, misappropriation or infringement of any third party right, including
            without limitation any intellectual property right, including but not limited to right of attribution, publicity,
            confidentiality, property or privacy right; (iv) your violation of any law, rule or regulation, including without
            limitation privacy and data security laws; or (v) any claim that your datasets or any analyses, data, or other
            nformation that you submit to the Divian Services or a User’s use of your datasets or any analyses, data, or
            other information that you submit to the Divian Services, caused damage to or harmed a User or third party,
            including without limitation claims that your datasets or any analyses, data, or other information that you submit
            to the Divian Services infringes, violates or misappropriates the rights of another.
          </div>
          <div style={{paddingTop: '3%'}}>
            <h6 className="h3">Limitation of Liability</h6>
            IN NO EVENT WILL DIVIAN BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR DAMAGES OF ANY KIND, INCLUDING, WITHOUT LIMITATION, DIRECT, SPECIAL, EXEMPLARY, INCIDENTAL, INDIRECT, PUNITIVE OR CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF USE, GOODWILL, DATA, REVENUE, BUSINESS OR PROFITS OR OTHER PECUNIARY DAMAGES) ARISING OUT OF OR IN CONNECTION WITH THE DIVIAN SERVICES AND/OR SOFTWARE APPLICATIONS, DATA, AND INFORMATION MADE AVAILABLE THROUGH THE DIVIAN SERVICES, INCLUDING WITHOUT LIMITATION RESULTING FROM: (I) THE USE OR THE INABILITY TO USE THE DIVIAN SERVICES OR ANY SOFTWARE APPLICATIONS, ANALYSES, DATA, AND INFORMATION MADE AVAILABLE THROUGH THE DIVIAN SERVICES; (II) ANY ERROR OR DEFECT IN THE DIVIAN SERVICES OR SOFTWARE APPLICATIONS MADE AVAILABLE THROUGH THE DIVIAN SERVICES; (III) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE DIVIAN SERVICES; (IV) UNAUTHORIZED ACCESS TO, USE OF OR ALTERATION OF YOUR TRANSMISSIONS OR DATA OR FOR ANY LOSS OF DATA; (V) STATEMENTS OR CONDUCT OF ANY USER OR THIRD-PARTY ON THE DIVIAN SERVICES; (VI) OR ANY OTHER MATTER RELATING TO THE Divian Services OR THE SOFTWARE APPLICATIONS, DATA, AND INFORMATION MADE AVAILABLE THROUGH THE DIVIAN SERVICES, WHETHER SUCH LIABILITY ARISES FROM ANY CLAIM BASED UPON CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, OR ANY OTHER LEGAL THEORY, WHETHER OR NOT DIVIAN HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.<p/>
            YOU SPECIFICALLY ACKNOWLEDGE THAT DIVIAN IS NOT LIABLE FOR THE DEFAMATORY, OFFENSIVE, INFRINGING, HARMFUL, OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES AND THAT THE RISK OF INJURY FROM THE FOREGOING RESTS ENTIRELY WITH YOU. FURTHER, DIVIAN WILL HAVE NO LIABILITY TO YOU OR TO ANY THIRD PARTY FOR ANY APPLICATIONS OR OTHER DATA OR INFORMATION UPLOADED ONTO OR DOWNLOADED OR THROUGH THE SERVICE, OR IF YOUR DATASETS, DATA OR INFORMATION IS LOST, CORRUPTED OR EXPOSED TO UNINTENDED THIRD PARTIES.<p/>
            YOU AGREE THAT THE TOTAL AGGREGATE LIABILITY OF DIVIAN TO YOU FOR ANY AND ALL CLAIMS ARISING FROM THE USE OF THE DIVIAN SERVICES, AND ANY APPLICATIONS, ANALYSES, CONTENT OR DATA MADE AVAILABLE THROUGH THE DIVIAN SERVICES, IS LIMITED TO THE GREATER OF (X) THE TOTAL AMOUNT PAID BY YOU TO DIVIAN FOR THE USE OF THE DIVIAN SERVICES TO WHICH THE CLAIM RELATES IN THE TWELVE MONTHS IMMEDIATELY PRECEDING THE DATE THE CAUSE OF ACTION AROSE AND (Y) ONE HUNDRED ($100) CANADIAN DOLLARS.<p/>
            THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN Divian AND YOU.<p/>

            The foregoing limitation of liability shall:<p/>
            <ul>
              <li>
              Apply regardless of whether (1) you base your claim on contract, tort, statute or any other legal theory, (2) we knew or should have known about the possibility of such damages, or (3) the limited remedies provided in this section fail of their essential purpose; and
              </li>
              <li>
              Not apply to any damage that Divian may cause you intentionally or knowingly in violation of the Terms of Use or applicable laws, rules, regulations or guidelines, or as otherwise mandated by applicable law that cannot be disclaimed from in this Agreement.
              </li>
            </ul>
            The foregoing limitation of liability shall survive any termination or expiration of these Terms of Use.
          </div>
          <div style={{paddingTop: '3%'}}>
            <h6 className="h3">Governing Law</h6>
            Except for any disputes relating to intellectual property rights, obligations or any infringement claims, any disputes with Divian arising out of or relating to the Agreement (“Disputes”) shall be governed by the law of the Province of Ontario regardless of your country of origin or where you access the Divian Services, and notwithstanding of any conflicts of law principles and the United Nations Convention for the International Sale of Goods.<p/>
            You acknowledge and agree that any claim or dispute arising out of these Terms of Use or your use of the Divian Services must be brought by you within one year from the date the cause of action first accrued.<p/>
            The foregoing provision shall survive any termination or expiration of these Terms of Use.
          </div>
          <div style={{paddingTop: '3%'}}>
            <h6 className="h3">General Terms</h6>
             <b>Severability.</b> If any provision of the Terms of Use is found by a court of competent jurisdiction or arbitrator
             to be illegal, void, or unenforceable, the unenforceable provision will be modified so as to render it enforceable
             and effective to the maximum extent possible in order to effect the intention of the provision; and if a court or
             arbitrator finds the modified provision invalid, illegal, void or unenforceable, the validity, legality and
             enforceability of the remaining provisions of these Terms of Use will not be affected in any way.<p/>

            <b>Notices.</b> We may post notices to you at www.divian.com or within the Divian Services. You may contact
            us via email at <b>legal@divian.com</b>. Any notices that you provide without compliance with this section on notices
            shall have no legal effect.<p/>

            <b>Entire Agreement.</b> You agree that the Terms of Use and all policies, terms, and other documents referenced
            herein or applicable to your use of the Divian Services constitute the entire, complete and exclusive agreement
            between you and us regarding the Divian Services and supersede all prior and contemporaneous agreements and
            understandings, whether written or oral, or whether established by custom, practice, policy or precedent, with
            espect to the subject matter hereof. You agree that any varying or additional terms contained in any purchase
            order or other written notification or document issued by you in relation to the Divian Services shall be of no
            effect and all such terms or conditions shall be null and void. You acknowledge and agree that your agreement
            hereunder is not contingent upon the delivery of any future functionality or features not specified herein or
            dependent upon any oral or written, public or private comments made by Divian with respect to future functionality
            or features for the Divian Services. You also may be subject to additional terms and conditions that may apply
            when you use or purchase certain other Divian Services or applications or third-party content or third party software.<p/>

            <b>No Informal Waivers, Agreements or Representations.</b> Our failure to act with respect to a breach of this
            Agreement by you or others does not waive our right to act with respect to that breach or subsequent similar or
            other breaches.<p/>

            <b>No Injunctive Relief.</b> In no event shall you seek or be entitled to rescission, injunctive or other
            equitable relief, or to enjoin or restrain the operation of the Divian Services.<p/>

            <b>Assignment and Delegation.</b> You may not assign or delegate any rights or obligations under these
            Terms of Use without our consent. Any purported assignment and delegation shall be ineffective. We may
            freely assign or delegate all rights and obligations under the Agreement, fully or partially without notice to you.<p/>

            <b>Export Control.</b> Your use of the Divian Services, including our software, is subject to export and
            re-export control laws and regulations. You shall not, directly or indirectly, sell, export, re-export,
            transfer, divert, or otherwise dispose of any software or service to any end-user without obtaining the
            required authorizations from the appropriate government authorities. You also warrant that you are not
            rohibited from receiving Canada origin products, including services or software.<p/>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
)

export default Terms
