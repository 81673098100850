import React, { useState } from 'react'
import {
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  Container,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import ContactUsModal from 'components/IndexPage/ContactUsModal'

const Footer = () => {
  const [showContactUs, setShowContactUs] = useState(false)
  return (
    <>
      <footer className="py-5" id="footer-main">
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="6">
              <div className="copyright text-center text-xl-left text-muted">
                © {new Date().getFullYear()}{" "}
                <a
                  className=" ml-1 text-muted"
                  href="/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Divian, Inc.
                </a>
              </div>
            </Col>
            <Col xl="6">
              <Nav className="nav-footer justify-content-center justify-content-xl-end">
                <NavItem>
                  <NavLink onClick={() => setShowContactUs(true)} tag={Link}>
                    Contact Us
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/terms" tag={Link}>
                    Terms of Use
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/privacy" tag={Link}>
                    Privacy Policy
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
      <ContactUsModal setShowContactUs={setShowContactUs} showContactUs={showContactUs} />
    </>
  )
}

export default Footer
