import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { openPopupWidget } from "react-calendly"
import brain from "../../assets/img/landing-page/brainV3.png"

const CALENDLY_URL = 'https://calendly.com/diviandemo/divian-demo?hide_gdpr_banner=1&primary_color=0a1138'

const CalendlyButton = ({ url, prefill, pageSettings, utm, size }) => (
  <Button
    color="default"
    size={size}
    className="text-truncate btn-v3"
    onClick={() => openPopupWidget({ url, prefill, pageSettings, utm })}
  >
    Book a demo
  </Button>
)

const Home = () => {
  const [isChanged, setIsChanged] = useState(false)

  const listenToScroll = () => (document.body.scrollTop || document.documentElement.scrollTop) > 50
    ? !isChanged && setIsChanged(true)
    : setIsChanged(false)

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section className="home-section d-flex flex-column">

      <header className={classNames('top-0 position-fixed', {
        'sticky-colored left-0 right-0': isChanged,
        'left-4 right-4': !isChanged,
      })}>
        <div className="content-restriction">
          <div className="d-flex justify-content-between align-items-center">
            <Link to={'/'} className="logo-v3">DIVIAN</Link>
            {isChanged && <CalendlyButton url={CALENDLY_URL} size="sm" />}
          </div>
        </div>
      </header>

      <div className="header-body flex-grow-1 pt-5 content-restriction w-100 d-flex align-items-center">

        <div className="brain-wrapper d-none d-md-block">
          <img src={brain} alt="" />
        </div>

        <div className="content-wrapper pl-0 pl-md-5">
          <div className="pb-1">
            <h1 className="ml-n1">Data at the speed <span className="text-nowrap">of thought</span></h1>
            <span className="sub-header">See what Generative AI can do for your business</span>
          </div>

          <div className="btn-wrapper d-flex align-items-center">
            <div className="left-side">
              <h2 className="ml-n1">Try Pilot Deployment</h2>
              <span className="sub-header">Low-cost opportunity to evaluate business-ready <span className="text-nowrap">Generative AI</span></span>
            </div>

            <div className="right-side pr-md-5">
              <CalendlyButton url={CALENDLY_URL} size="bg" />
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default Home
