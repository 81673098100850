import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ContactUsModal from 'components/IndexPage/ContactUsModal'

const Answers = () => {
  const [showContactUs, setShowContactUs] = useState(false)

  return (
    <footer className="footer-section">
      <div className="content-restriction">
        <ul className="d-flex justify-content-center pt-4 pb-0 px-0 m-0">
          <li>
            <Link to={'#'} onClick={() => setShowContactUs(true)}>Contact us</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy policy</Link>
          </li>
          <li>
            <Link to="/terms">Terms of Use</Link>
          </li>
        </ul>

        <div className="copyright py-3">
          © {new Date().getFullYear()} Divian, Inc.
        </div>

        <ContactUsModal setShowContactUs={setShowContactUs} showContactUs={showContactUs} />
      </div>
    </footer>
  )
}

export default Answers
