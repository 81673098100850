import React from 'react'
import {
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Button,
  Col,
} from "reactstrap"

const Learn = () => (
  <section>
    <div style={{ height: '40px' }} />
    <Container>
      <Row className="justify-content-center">
        <Col md={8} className="text-center">
          <h1 className="title" style={{ marginLeft: '-4px', marginTop: '10%' }}>Learn Divian</h1>
          <h4>Your path to learning predictive analytics</h4>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md={8} className="text-center">
          <h2 class="title">Tutorials</h2>
          <h5 class="description">With Divian you can learn by doing. Our hands-on guides
            show how to solve practical, real-world data problems using Divian tools.
          </h5><br/>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md={6} style={{marginTop: '7%'}}>
          <Card data-background="image">
            <CardBody>
              <h6 className="card-category">Tutorial</h6>
              <h3 className="card-title">Divian Basics</h3>
              <p className="card-description">
                Learn how to import and clean data, and build predictive models using Divian Platform.
              </p>
              <CardFooter>
                <Button
                  className="btn card-link"
                  color="success"
                  href="https://learn.divian.com/courses/2"
                  target="_blank"
                >
                  <i aria-hidden={true} className="fa fa-file-text"></i>
                  Read More
                </Button>
              </CardFooter>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md={6} style={{marginTop: '7%'}}>
          <Card data-background="image">
            <CardBody>
              <h6 className="card-category">Tutorial</h6>
              <h3 className="card-title">Forecast Cryptocurrency Prices</h3>
              <p className="card-description">
                Build and test the ML model to forecast future price of Bitcoin.
              </p>
              <CardFooter>
                <Button
                  className="btn card-link"
                  color="success"
                  href="https://learn.divian.com/courses/3"
                  target="_blank"
                >
                  <i aria-hidden={true} className="fa fa-file-text"></i>
                  Read More
                </Button>
              </CardFooter>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </section>
)

export default Learn
