import React from 'react'
import { Link } from 'react-router-dom'
import {
  NavbarBrand,
  Navbar,
  Container,
} from 'reactstrap'

import BlacklogoSvg from 'assets/img/landing-page/black-logo.svg'

const IndexNavbar = () => (
  <>
    <Navbar
      className="navbar-horizontal navbar-main navbar-transparent"
      expand="lg"
      id="navbar-main"
    >
      <Container>
        <NavbarBrand to="/" tag={Link}>
          <img style={{ width: '80px' }}
            alt="..."
            src={BlacklogoSvg}
          />
        </NavbarBrand>
        {/* <button
          aria-controls="navbar-collapse"
          aria-expanded={false}
          aria-label="Toggle navigation"
          className="navbar-toggler"
          data-target="#navbar-collapse"
          data-toggle="collapse"
          id="navbar-collapse"
          type="button"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <UncontrolledCollapse
          className="navbar-custom-collapse"
          navbar
          toggler="#navbar-collapse"
        >
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                <Link to="/">
                  <img
                    alt="..."
                    src={DivianLogoBlack}
                  />
                </Link>
              </Col>
              <Col className="collapse-close" xs="6">
                <button
                  aria-controls="navbar-collapse"
                  aria-expanded={false}
                  aria-label="Toggle navigation"
                  className="navbar-toggler"
                  data-target="#navbar-collapse"
                  data-toggle="collapse"
                  id="navbar-collapse"
                  type="button"
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink to="/learn" tag={Link}>
                <span className="nav-link-inner--text">Learn</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/api" tag={Link}>
                <span className="nav-link-inner--text">API</span>
              </NavLink>
            </NavItem>
          </Nav>
          <hr className="d-lg-none" />
          <Nav className="align-items-lg-center ml-lg-auto" navbar>
            <NavItem>
              <NavLink
                className="nav-link-icon"
                href="/"
                id="tooltip366258619"
                target="_blank"
              >
                <i className="fab fa-twitter-square" />
                <span className="nav-link-inner--text d-lg-none">
                  Twitter
                </span>
              </NavLink>
              <UncontrolledTooltip delay={0} target="tooltip366258619">
                Follow us on Twitter
              </UncontrolledTooltip>
            </NavItem>
            <NavItem>
              <NavLink
                className="nav-link-icon"
                href="/"
                id="tooltip931502898"
                target="_blank"
              >
                <i className="fab fa-github" />
                <span className="nav-link-inner--text d-lg-none">Github</span>
              </NavLink>
              <UncontrolledTooltip delay={0} target="tooltip931502898">
                Star us on Github
              </UncontrolledTooltip>
            </NavItem>
            <NavItem className="d-none d-lg-block ml-lg-4">
              <Button
                className="btn-info btn-icon bg-gradient-default btn-block"
                size="sm"
                color="default"
                href={`https://cloud.divian.${REACT_APP_ENVIRONMENT === 'production' ? 'com' : 'dev'}/login`}
              >
                &nbsp;<span className="btn-inner--icon">
                  <i className="ni ni-bold-right" />
                </span>&nbsp;
                <span className="nav-link-inner-text">Log in</span>&nbsp;&nbsp;
              </Button>
            </NavItem>
          </Nav>
        </UncontrolledCollapse> */}
      </Container>
    </Navbar>
  </>
)

export default IndexNavbar
