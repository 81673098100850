import React from 'react'
import { Row, Col } from 'reactstrap'
import { Database, File, Repeat } from 'react-feather'
import Brain from 'assets/img/landing-page/brainicon.svg'

const BTL = () => (
  <section className="BTL-section">
    <div className="content-restriction">
      <h3 className="ml-n1">Bring the power of generative AI to your business</h3>
      <span className="sub-header">Seamlessly integrate LLMs with your data and tools</span>

      <Row className="py-5">
        <Col xs={12}>
          <Row className="pb-5 flex-sm-row flex-column">
            <Col xs={12} sm={5} className="d-flex align-items-center justify-content-center left-block">
              <h4>
                <img
                  alt=".."
                  src={Brain}
                />
              </h4>
            </Col>
            <Col xs={1} className="middle-block d-sm-block d-none" />
            <Col xs={12} sm={6} className="right-block d-flex flex-column justify-content-center">
              <h4>Large Language Models</h4>
              Use the power of state-of-the-art models that comprehend natural language requests and and excel at generating human-like answers and database queries.
            </Col>
          </Row>

          <Row className="pb-5 flex-sm-row flex-column">
            <Col xs={12} sm={5} className="d-flex align-items-center justify-content-center left-block arrow">
              <h4 className="special">
                <Repeat className="" strokeWidth="1" />
              </h4>
            </Col>
            <Col xs={1} className="middle-block d-sm-block d-none" />
            <Col xs={12} sm={6} className="right-block d-flex flex-column justify-content-center">
              <h4>Business Translation Layer</h4>
              Ensure a secure and efficient integration between your enterprise data, tools, and desired actions. It acts as a robust intermediary, augmenting LLMs with enterprise-specific knowledge and providing them with relevant instructions to generate queries. It seamlessly executes the necessary actions to extract valuable answers from your enterprise sources.
            </Col>
          </Row>

          <Row className="pb-4 flex-sm-row flex-column">
            <Col xs={12} sm={5} className="d-flex align-items-center justify-content-center left-block arrow">
              <h4 className="double">
               <Database strokeWidth="1" />
               <File className="ml-2" strokeWidth="1" />
              </h4>
            </Col>
            <Col xs={1} className="middle-block d-sm-block d-none" />
            <Col xs={12} sm={6} className="right-block d-flex flex-column justify-content-center">
              <h4>Knowledge Sources & Tools</h4>
              The wealth of your enterprise data sources, knowledge bases, and tools forms the foundation of the business translation layer, enabling it to enhance the capabilities of LLMs.
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  </section>
)

export default BTL
