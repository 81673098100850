import React from 'react'
import {
  MessageSquare, Search, Monitor, Settings,
} from 'react-feather'
import Brain from 'assets/img/landing-page/brainicon.svg'

const Dataverse = () => (
  <section className="dataverse-section">
    <div className="content-restriction mt-0 border-0 pt-0">
      <div className="content-wrapper py-6">
        <dl className="m-0 pb-0 pt-1 px-3 d-flex flex-column dl__border-left">
          <dt className="align-self-start ml-n4">
            <MessageSquare className="mr-3 mb-1" strokeWidth="1" />
             Ask a question
          </dt>
          <dd>
            <ul>
              <li>Start by asking a question as if you are speaking with a colleague.</li>
              <li>No need to write queries or learn database structures.</li>
            </ul>
          </dd>
        </dl>

        <dl className="m-0 py-0 px-3 d-flex flex-column dl__border-right">
          <dt className="align-self-end mr-n4 arrow">
            <img
              className="mr-3 mb-1"
              alt=".."
              src={Brain}
            />
            AI comprehends meaning
          </dt>
          <dd>
            <ul>
              <li>The AI system understands the meaning and context, finding concepts and entities in your question.</li>
            </ul>
          </dd>
        </dl>

        <dl className="m-0 py-0 px-3 d-flex flex-column dl__border-left">
          <dt className="align-self-start ml-n4 arrow">
            <Search className="mr-3 mb-1" strokeWidth="1" />
            Search across sources
          </dt>
          <dd>
            <ul>
              <li>Using the business translation layer, the AI bridges technical data with business concepts to find relevant data sources.</li>
              <li>You can always update the AI system “brains” with new sources as they become available.</li>
              <li>So, the solution grows and changes with your business.</li>
            </ul>
          </dd>
        </dl>

        <dl className="m-0 py-0 px-3 d-flex flex-column dl__border-right">
          <dt className="align-self-end mr-n4 arrow">
            <Settings className="mr-3 mb-1" strokeWidth="1" />
            Retrieve relevant data
          </dt>
          <dd>
            <ul>
              <li>Once AI finds relevant sources, it generates queries and extracts the data, or summarizes information in your documents.</li>
            </ul>
          </dd>
        </dl>

        <dl className="m-0 py-0 px-3 d-flex flex-column dl__border-left">
          <dt className="align-self-start ml-n4 arrow">
            <Monitor className="mr-3 mb-1" strokeWidth="1" />
            Present clear results
          </dt>
          <dd className="border-0">
            <ul>
              <li>Finally, receive an answer to your question from the AI in a form of tables or plain text.</li>
            </ul>
          </dd>
        </dl>
      </div>
    </div>
  </section>
)

export default Dataverse
