import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, Button } from 'reactstrap'

import AlertNotification from 'components/AlertNotification'

import { useQuery } from 'helpers/useQuery'
import { apiRequest } from 'helpers/request'

const Unsubscribe = () => {
  const history = useHistory()
  const [showSuccess, setShowSuccess] = useState(false)
  const { getParam } = useQuery()
  const token = getParam('token')
  const ps = getParam('ps')

  if (!token || !ps) history.push('/')

  const unsubscribe = async () => {
    try {
      await apiRequest({
        url: 'unsubscribe',
        method: 'POST',
        data: { token, ps },
      })
      setShowSuccess(true)
      setTimeout(() => {
        setShowSuccess(false)
        history.push('/')
      }, 5000)
    } catch (err) {
      console.error(err)
    }
  }

  return(
    <div className="header-1">
      <div className="page-header">
        <div className="content-center">
          <Container>
            <div className="text-center">
              <h1 className="title">Unsubscribe</h1>
              <h4><strong>We are so sorry to see you go :(</strong></h4><br/>
              <br />
              <h5><strong>Are you sure you want to unsubscribe?</strong></h5>
              <Button
                onClick={unsubscribe}
                className="btn btn-info btn-lg"
              >
                Confirm
              </Button>
            </div>
          </Container>
        </div>
      </div>
      <AlertNotification
        opened={showSuccess}
        message="Success!"
      />
    </div>
  )
}

export default Unsubscribe
