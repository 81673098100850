import React from 'react'
import parse from 'html-react-parser'

import {
  Alert,
  Container,
} from 'reactstrap'

const AlertNotification = ({ color = 'info', opened, message }) => (
  <Alert
    color={color}
    isOpen={opened}
    style={{ position: 'fixed', top: 0, width: '100%', left: 0, textAlign: 'center' }}
  >
    <Container>
      <span>{message ? parse(message) : null}</span>
    </Container>
  </Alert>
)

export default AlertNotification
