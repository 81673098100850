import React from 'react'

import Home from 'components/LandingPageV3/Home'
import BTL from 'components/LandingPageV3/BTL'
import Answers from 'components/LandingPageV3/Answers'
import Dataverse from 'components/LandingPageV3/Dataverse'
import Footer from 'components/LandingPageV3/Footer'

const LandingPageV3 = () => (
  <div className="landing-v3-wrapper position-relative overflow-hidden px-4">
    <Home />
    <BTL />
    <Answers />
    <Dataverse />
    <Footer />
  </div>
)

export default LandingPageV3
