import axios from 'axios'

const { REACT_APP_ENVIRONMENT } = process.env
const REACT_APP_API_BASE_URL = `https://api.divian.${REACT_APP_ENVIRONMENT === 'production' ? 'com' : 'dev'}/`

const formInstance = axios.create()
const apiInstance = axios.create()

function createFormData(data, formData = new window.FormData(), previousKey) {
  if (Array.isArray(data)) {
    data.forEach((val, index) => {
      createFormData(val, formData, `${previousKey}[${index}]`)
    })
  } else if (data instanceof Object && !(data instanceof window.Blob)) {
    Object.keys(data).forEach((key) => {
      const value = data[key]
      if (previousKey) key = `${previousKey}[${key}]` //eslint-disable-line
      createFormData(value, formData, key)
    })
  } else if (data !== undefined && data !== null) {
    formData.append(previousKey, data)
  }

  return formData
}

export const apiRequest = ({
  multipart,
  data,
  ...rest
}) => {
  const formData = multipart ? createFormData(data) : data
  return apiInstance({
    method: 'GET',
    baseURL: REACT_APP_API_BASE_URL,
    headers: {
      Pragma: 'no-cache',
      Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
      'Cache-Control': 'no-cache',
    },
    data: formData,
    ...rest,
  })
}

export const requestCall = ({
  multipart,
  data,
  ...rest
}) => {
  const formData = multipart ? createFormData(data) : data
  return formInstance({
    method: 'GET',
    baseURL: REACT_APP_API_BASE_URL,
    headers: {
      Pragma: 'no-cache',
      Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
      'Cache-Control': 'no-cache',
    },
    data: formData,
    ...rest,
  })
}
