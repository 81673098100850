import { useLocation } from 'react-router-dom'

export const useQuery = () => {
  const location = useLocation()

  const getParam = (name) => {
    const url = new URLSearchParams(location.search)
    return url.get(name)
  }

  return { getParam }
}
